import Loadable from 'react-loadable'

const kissoRoutes = [
    {
        path: '/home_social',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-homeSocial" */ '../pages/home_social'),
            loading: () => null,
        }),
    },
    {
        path: '/html/send_email_request_delete_accountReminder',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-homeSocial" */ '../pages/html/serda'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/social/help/questions',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridSocialHelpQuestions" */ '../pages/helpQuestions_social'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/social/help/questions/en',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridSocialHelpQuestionsEn" */ '../pages/helpQuestions_social/helpQuestionsEn'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/social/about/ts',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutTsSocial" */ '../pages/aboutTs_social'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/social/about/pp',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutPpSocial" */ '../pages/aboutPp_social'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/social/about/aboutCsap',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutCsapScoial" */ '../pages/aboutCsap_social'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/social/about/dp',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutDpSocial" */ '../pages/aboutDp_social'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/share/dynamic',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridShareDynamic" */ '../pages/share/dynamic'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/vip/autoRenew',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hyBridVipAutoRenew" */ '../pages/vipAutoRenew'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/vip/autoRenew/zh',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hyBridVipAutoRenewZh" */ '../pages/vipAutoRenew/vipAutoRenewZh'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/vip/autoRenew/en',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hyBridVipAutoRenewEn" */ '../pages/vipAutoRenew/vipAutoRenewEn'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/vip/autoRenew/th',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hyBridVipAutoRenewTh" */ '../pages/vipAutoRenew/vipAutoRenewTh'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/vip/autoRenew/my',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hyBridVipAutoRenewMy" */ '../pages/vipAutoRenew/vipAutoRenewMy'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/vip/autoRenew/id',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hyBridVipAutoRenewId" */ '../pages/vipAutoRenew/vipAutoRenewId'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/vip/autoRenew/vi',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hyBridVipAutoRenewVi" */ '../pages/vipAutoRenew/vipAutoRenewVi'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/vip/autoRenew/tr',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hyBridVipAutoRenewTr" */ '../pages/vipAutoRenew/vipAutoRenewTr'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/vip/autoRenew/zh_TW',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hyBridVipAutoRenewTw" */ '../pages/vipAutoRenew/vipAutoRenewTw'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/vip/autoRenew/ar',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hyBridVipAutoRenewAr" */ '../pages/vipAutoRenew/vipAutoRenewAr'),
            loading: () => null,
        }),
    },
    // 联系我们
    {
        path: '/hybrid/social/about/contact',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutContact" */ '../pages/aboutContact_kisso'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/social/about/contact/en',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutContactEn" */ '../pages/aboutContact_kisso/aboutContactEn'),
            loading: () => null,
        }),
    },
    // 帮助
    {
        path: '/hybrid/social/help/questions',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridHelpQuestions" */ '../pages/helpQuestions_social'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/social/help/questions/en',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridHelpQuestionsEn" */ '../pages/helpQuestions_social/helpQuestionsEn'),
            loading: () => null,
        }),
    },
    // 第三方支付
    {
        path: '/hybrid/payment',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridPayment" */ '../pages/recharge/payment'),
            loading: () => null,
        }),
    },

    // 排行榜
    {
        path: '/hybrid/social/billboard/rule',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridBillboardRule" */ '../pages/billboardRule_social'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/billboard/rule/en',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridBillboardRuleEn" */ '../pages/billboardRule_social/billboardRuleEn'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/billboard/rule/zh',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridBillboardRuleCn" */ '../pages/billboardRule_social/billboardRuleCn'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/billboard/rule/ar',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridBillboardRuleAr" */ '../pages/billboardRule_social/billboardRuleAr'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/billboard/rule/tr',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridBillboardRuleTr" */ '../pages/billboardRule_social/billboardRuleTr'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/billboard/rule/hi',  // 印地语
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridBillboardRule" */ '../pages/billboardRule_social/billboardRuleHi'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/billboard/rule/id',  // 印尼语
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridBillboardRule" */ '../pages/billboardRule_social/billboardRuleId'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/billboard/rule/vi',  // 印尼语
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridBillboardRule" */ '../pages/billboardRule_social/billboardRuleVi'),
            loading: () => null,
        }),
    },

    // ios 403问题新加路由
    {
        path: '/hybrid/social/ios/about/dp',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridAboutPpSocial" */ '../pages/aboutDp_social/ios'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/social/ios/help/questions',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridSocialHelpQuestions" */ '../pages/helpQuestions_social/ios'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/social/ios/help/questions/en',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridSocialHelpQuestionsEn" */ '../pages/helpQuestions_social/ios/helpQuestionsEn'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/family/rule',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridFamilyRule" */ '../pages/familyRule'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/family/rule/en',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridFamilyRuleEn" */ '../pages/familyRule/familyRuleEn'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/family/rule/zh',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridFamilyRuleCn" */ '../pages/familyRule/familyRuleCn'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/family/rule/ar',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridFamilyRuleAr" */ '../pages/familyRule/familyRuleAr'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/family/rule/tr',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridFamilyRuleTr" */ '../pages/familyRule/familyRuleTr'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/family/rule/hi',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridFamilyRuleTr" */ '../pages/familyRule/familyRuleHi'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/family/rule/id',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridFamilyRuleTr" */ '../pages/familyRule/familyRuleId'),
            loading: () => null,
        }),
    },
    {
        path: '/hybrid/family/rule/vi',
        exact: true,
        component: Loadable({
            loader: () =>
                import(/* webpackChunkName: "page-hybridFamilyRuleTr" */ '../pages/familyRule/familyRuleVi'),
            loading: () => null,
        }),
    },
]

export default kissoRoutes
